<template>
  <div class="produceResource dataM">
    <!-- 统计数据 -->
    <div class="infoBox">
      <div class="info">
        <div class="item">
          <label>生产设备</label>
          <div class="value">{{statistics.productionEquipmentNumber}}</div>
        </div>
        <!-- <div class="item">
          <label>检测设备</label>
          <div class="value">{{statistics.testEquipmentNumber}}</div>
        </div>
        <div class="item">
          <label>工作人员</label>
          <div class="value">{{statistics.staffInfoNumber}}</div>
        </div>
        <div class="item">
          <label>辅助资源</label>
          <div class="value">{{statistics.assistantResourcesNumber}}</div>
        </div> -->
      </div>
    </div>
    <div class="tabData">
      <div class="tool" >
        <!-- <upload @uploadFile="uploadFile" ref="upload"/> -->
        <upload
          ref="upload"
          templateName="生产设备导入模板"
          templateParam="productionEquipment"
          @handleSearch="handleSearch"
          v-premiSub="'生产设备_导入'"
        />
        <el-tooltip v-premiSub="'生产设备_新建'" effect="dark" content="新增" placement="top">
          <img src="@/assets/images/dataManage/add.png" @click="handleCreate"/>
        </el-tooltip>
        <el-tooltip v-premiSub="'生产设备_删除'" effect="dark" content="删除" placement="top">
          <img src="@/assets/images/dataManage/batchdel.png" @click="comfirm('')"/>
        </el-tooltip>
        <!-- <img src="@/assets/images/dataManage/copy.png"/> -->
        <el-input
          v-model="searchValue"
          style="width:220px"
          size="small"
          :placeholder="placeholder[activeTab]"
          v-premiSub="'生产设备_搜索'"
          @keydown.enter.native="handleSearch(1)"
          >
          <i slot="suffix" class="el-input__icon el-icon-search" @click="handleSearch(1)"></i>
        </el-input>
      </div>
      <el-tabs v-model="activeTab">
        <el-tab-pane label="生产设备" name="0">
          <productionEquipment
            :page="page"
            :size="size"
            :tableList="tableList" 
            :statusType.sync="statusType" 
            :multipleSelection.sync="multipleSelection"
            @handleSearch="handleSearch" 
            @comfirm="comfirm"
          />
        </el-tab-pane>
        <!-- <el-tab-pane label="检测设备" name="1">
          <testEquipment
            :page="page"
            :tableList="tableList" 
            :statusType.sync="statusType" 
            :multipleSelection.sync="multipleSelection"
            @handleSearch="handleSearch" 
            @comfirm="comfirm"
          />
        </el-tab-pane>
        <el-tab-pane label="工作人员" name="2">
          <worker
            :page="page"
            :tableList="tableList" 
            :statusType.sync="statusType" 
            :multipleSelection.sync="multipleSelection"
            @handleSearch="handleSearch" 
            @comfirm="comfirm"
          />
        </el-tab-pane>
        <el-tab-pane label="辅助资源" name="3">
          <el-button :type="resourceType == 1 ? 'primary' : ''" size="small" @click="resourceType = 1">夹具</el-button>
          <el-button :type="resourceType == 3 ? 'primary' : ''" size="small" @click="resourceType = 3">检具</el-button>
          <el-button :type="resourceType == 2 ? 'primary' : ''" size="small" @click="resourceType = 2">刀具</el-button>
          <auxiliaryResources
            :page="page"
            :tableList="tableList" 
            :statusType.sync="statusType"
            :multipleSelection.sync="multipleSelection"
            @handleSearch="handleSearch" 
            @comfirm="comfirm"
          />
        </el-tab-pane> -->
      </el-tabs>
      <pagination :total="total" :page.sync="page" :size.sync="size"  @getTableData="handleSearch"/>
    </div>
    <!-- 导入结果弹窗 -->
    <resultDialog 
      :name="tab[activeTab]" 
      :resultVisible.sync="resultVisible" 
      :type="resultType" 
      :fileName="fileName"
      :resultMsg="resultMsg"
      @reUpload="reUpload"
    />
    <!-- 新建/编辑 -->
    <Component 
      v-if="createVisible" 
      :is="createCom" 
      :createVisible.sync="createVisible"
      type="create"
      :resourceType="resourceType"
      @handleSearch="handleSearch"
    />
  </div>
</template>

<script>
const productionEquipment = () => import('./components/table/productionEquipment.vue')
const testEquipment = () => import('./components/table/testEquipment.vue')
const worker = () =>import('./components/table/worker.vue')
const auxiliaryResources = () =>import('./components/table/auxiliaryResources.vue')
const pagination = () => import('@/components/pagination')
const upload = () => import('@/components/upload/upload')
const resultDialog = () => import('@/components/upload/resultDialog')
const productionEquipmentDialog = () => import('./components/dialog/productionEquipmentDialog.vue')
const testEquipmentDialog = () => import('./components/dialog/testEquipmentDialog.vue')
const workerDialog = () => import('./components/dialog/workerDialog.vue')
const auxiliaryResourcesDialog = () => import('./components/dialog/auxiliaryResourcesDialog.vue')
import { isComfirm } from '@/utils/data.js'
import { 
  summaryStatistics,
  getEquipment,
  getTestEquipment,
  getStaffList,
  getAssistantResources,
  delProEquipment,
  delTestEquipment,
  delStaff,
  delAssistantResources,
  importEquipment,
  importTestEquipment,
  importStaff,
  importAssistant,
  delProEquipmentBatch,
  delTestEquipmentBatch,
  delStaffBatch,
  delAssistantResourcesBatch
  } from '@/api/data.js'
export default {
  name:"produceResource",
  components:{
    pagination,
    productionEquipment,
    testEquipment,
    worker,
    auxiliaryResources,
    upload,
    resultDialog,
    productionEquipmentDialog,
    testEquipmentDialog,
    workerDialog,
    auxiliaryResourcesDialog
  },
  data(){
    return{
      activeTab:'0',
      tab:['生产设备','检测设备','工作人员','辅助资源'],
      placeholder:['请输入设备编号/名称','请输入设备编号/名称','员工编号/姓名/部门/岗位','请输入编号/名称'],
      total:0,
      page:1,
      size:10,
      searchValue:'',
      statusType:'',
      resultVisible:false,
      resultType:true,
      resultMsg:'',
      fileName:'',
      statistics:{
        "productionEquipmentNumber": 0,
        "testEquipmentNumber": 0,
        "staffInfoNumber": 0,
        "assistantResourcesNumber": 0
      },
      tableList:[],
      resourceType:1,// 1：夹具，2：刀具，3：检具
      currentData:null,
      createVisible:false,
      createCom:'',//新建组件类型
      multipleSelection:[]
    }
  },
  watch:{
    activeTab(){
      this.statusType = ''
      this.handleSearch()
    },
    size(){
      this.handleSearch(1)
    },
    resourceType(){
      this.handleSearch(1)
    },
    statusType(val){
      val !== '' && this.handleSearch(1)
    }
  },
  activated(){
    // this.getStatistics()
    this.handleSearch()
  },
  methods:{
    //查询总统计数据
    getStatistics(){
      summaryStatistics().then(res=>{
        if(res.code ===0){
          this.statistics = res.data
        }
      })
    },
    //查询表格数据
    handleSearch(page){
      page ? this.page = page : this.page =1
      let searchList = [getEquipment,getTestEquipment,getStaffList,getAssistantResources]
      let Api = searchList[this.activeTab]
      let params = {
        page:this.page,
        size:this.size,
        searchValue:this.searchValue,
        statusType:this.statusType || ''
      }
      this.activeTab == 3 && (params.resourceType = this.resourceType)
      Api(params).then(res=>{
        if(res.code === 0){
          this.tableList = res.data.records
          this.total = res.data.total
          this.getStatistics()
        }else{
          this.$message.error(res.msg || '接口错误')
        }
      })
    },
    comfirm(row){
      if(!row && !this.multipleSelection.length){
        this.$message.warning('请选择要删除的数据')
        return
      }
      isComfirm(row,row ? this.del : this.batchdel)
    },
    //删除生产设备/检测设备/工作人员/辅助资源
    del(row){
      let delList = [delProEquipment,delTestEquipment,delStaff,delAssistantResources]
      let Api = delList[this.activeTab]
      Api(row.id).then(res=>{
        if(res.code === 0){
          this.$message.success('删除成功')
          this.getStatistics()
          this.handleSearch()
        }else{
          this.$message.error(res.msg || '接口失败')
        }
      })
    },
    batchdel(){
      let delList = [delProEquipmentBatch,delTestEquipmentBatch,delStaffBatch,delAssistantResourcesBatch]
      let Api = delList[this.activeTab]
      Api(this.multipleSelection).then(res=>{
        if(res.code === 0){
          this.$message.success('删除成功')
          this.getStatistics()
          this.handleSearch()
        }else{
          this.$message.error(res.msg || '接口失败')
        }
      })
    },
    httpRequest(){},
    uploadFile(file){
      let formData = new FormData();
      formData.append('file',file.raw);
      this.fileName = file.name
      let importList = [importEquipment,importTestEquipment,importStaff,importAssistant]
      let Api = importList[this.activeTab]
      Api(formData).then(res=>{
        this.resultMsg = res.msg
        if(res.code == 0){
          this.resultVisible = true
          this.resultType = true
          this.handleSearch(1)
        }else{
          this.resultVisible = true
          this.resultType = false
        }
      }).catch((error)=>{
        this.resultMsg = error.response?.data?.msg
        this.resultVisible = true
        this.resultType = false
      })
    },
    reUpload(){
      this.$refs.upload.reUpload()
    },
    handleCreate(){
      // let ComList = ['productionEquipmentDialog','testEquipmentDialog','workerDialog','auxiliaryResourcesDialog']
      // this.createCom = ComList[this.activeTab]
      // this.createVisible = true
      this.$router.push({
        path:'editproduceResource'
      })
    }
  }
}
</script>

<style lang="scss">
.produceResource{
  text-align: left;
  color: #E8E8E8;
  margin-top: -5px;
  padding: 0px 2px;
  img{
    width: 22px;
    height: 22px;
    cursor: pointer;
  }
  .infoBox{
    background: #232323;
    padding: 20px 35px;
    .info{
      // display: flex;
      // justify-content: space-around;
      // text-align: center;
      margin-left: 150px;
      .item{
        display: flex;
        flex-direction: column;
        font-size: 16px;
        width: 100%;
        position: relative;
        .value{
          font-size: 40px;
          color: #2DEB79;
          line-height: 60px;
        }
      }
      .item:nth-child(n+2){
        &:before{
          content: '';
          display: inline-block;
          height: 58px;
          width: 1px;
          background: #E8E8E8;
          position: absolute;
          bottom: 15px;
        }
      }
    }
  }
  .tabData{
    margin: 16px 30px;
    padding: 20px 32px 80px 32px;
    background: #232323;
    border-radius: 8px;
    min-height: calc(100vh - 380px);
    position: relative;
    .tool{
      position: absolute;
      right: 30px;
      display: flex;
      align-items: center;
      z-index:2;
      img{
        margin-right: 30px;
      }
    }
  }
}
</style>